import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query UserHasLicense {\n    me {\n      licenses(first: 1) {\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        edges {\n          node {\n            id\n            uuid\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import gql from "graphql-tag";
import ApolloClient from 'apollo-boost';
import { getAuthToken } from "@saleor/sdk";
import { commonMessages } from "@temp/intl";
import { Checkbox } from "@components/atoms";
import QuantityInput from "../../molecules/QuantityInput";
import AddToCartButton from "../../molecules/AddToCartButton";
import ProductVariantPicker from "../ProductVariantPicker";
import * as S from "./styles";
import { getAvailableQuantity, getProductPrice, canAddToCart } from "./stockHelpers";
var LOW_STOCK_QUANTITY = 5;
var userHasLicense = gql(_templateObject());

var AddToCartSection = function AddToCartSection(_ref) {
  var availableForPurchase = _ref.availableForPurchase,
      isAvailableForPurchase = _ref.isAvailableForPurchase,
      isDynfiLicense = _ref.isDynfiLicense,
      items = _ref.items,
      name = _ref.name,
      productPricing = _ref.productPricing,
      productVariants = _ref.productVariants,
      queryAttributes = _ref.queryAttributes,
      onAddToCart = _ref.onAddToCart,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      setVariantId = _ref.setVariantId,
      variantId = _ref.variantId,
      components = _ref.components,
      componentOf = _ref.componentOf,
      simpleAvailability = _ref.simpleAvailability;
  var intl = useIntl();

  var _useState = useState(isDynfiLicense ? 4 : 1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      variantStock = _useState4[0],
      setVariantStock = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      variantPricing = _useState6[0],
      setVariantPricing = _useState6[1];

  var availableQuantity = getAvailableQuantity(items, variantId, variantStock);

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      showLicenseWarning = _useState8[0],
      setShowLicenseWarning = _useState8[1];

  var isOutOfStock = !!variantId && variantStock === 0;
  var noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  var purchaseAvailableDate = !isAvailableForPurchase && availableForPurchase && Date.parse(availableForPurchase);
  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity;
  var isLowStock = !!variantId && !isOutOfStock && !isNoItemsAvailable && availableQuantity < LOW_STOCK_QUANTITY;
  var disableButton = !canAddToCart(items, !!isAvailableForPurchase && (simpleAvailability == null || simpleAvailability == 'AVAILABLE'), variantId, variantStock, quantity);

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, null, message);
  };

  var _useState9 = useState(),
      _useState10 = _slicedToArray(_useState9, 2),
      licensePricing = _useState10[0],
      setLicensePricing = _useState10[1];

  var _useState11 = useState(),
      _useState12 = _slicedToArray(_useState11, 2),
      contractUrl = _useState12[0],
      setContractUrl = _useState12[1];

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      setContractUrl(null);
      setLicensePricing(null);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
    setContractUrl(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.contractUrl);
    setLicensePricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.licensePricing);
  };

  var label = isDynfiLicense ? intl.formatMessage(commonMessages.numberOfDevices) : null;
  var hasComponents = components && components.length;

  var _useState13 = useState({}),
      _useState14 = _slicedToArray(_useState13, 2),
      selectedComponents = _useState14[0],
      setSelectedComponents = _useState14[1];

  var changeComponentSelection = function changeComponentSelection(id) {
    var _sc = Object.assign({}, selectedComponents);

    var component = null;

    for (var i in components) {
      if (components[i].id == id) {
        component = components[i];
        break;
      }
    }

    var carr = component.sku.split('_');
    var ctype = carr[0] == 'EXT' && carr.length > 1 ? carr[1] : carr[0];

    for (var i in components) {
      var c = components[i];

      if (c.id in _sc && _sc[c.id]) {
        if (c.id == id) {
          delete _sc[c.id];
        } else {
          var _carr = c.sku.split('_');

          var _ctype = _carr[0] == 'EXT' && _carr.length > 1 ? _carr[1] : _carr[0];

          if (ctype == _ctype) delete _sc[c.id];
        }
      } else {
        if (c.id == id) _sc[c.id] = true;
      }
    }

    console.dir(_sc);
    setSelectedComponents(_sc);
  };

  if (isDynfiLicense) {
    var _currency = window.localStorage.getItem('currency');

    var currency = _currency ? JSON.parse(_currency) : 'EUR';
    var authToken = getAuthToken();
    var client = new ApolloClient({
      uri: process.env.API_URI,
      headers: {
        Authorization: authToken ? "JWT ".concat(authToken) : null,
        Currency: currency
      }
    });
    client.query({
      query: userHasLicense
    }).then(function (resp) {
      var _resp$data, _resp$data$me, _resp$data$me$license, _resp$data$me$license2;

      if ((_resp$data = resp.data) === null || _resp$data === void 0 ? void 0 : (_resp$data$me = _resp$data.me) === null || _resp$data$me === void 0 ? void 0 : (_resp$data$me$license = _resp$data$me.licenses) === null || _resp$data$me$license === void 0 ? void 0 : (_resp$data$me$license2 = _resp$data$me$license.edges) === null || _resp$data$me$license2 === void 0 ? void 0 : _resp$data$me$license2.length) {
        setShowLicenseWarning(true);
      }
    });
  }

  return React.createElement(S.AddToCartSelection, null, React.createElement(S.ProductNameHeader, {
    "data-test": "productName"
  }, name), isOutOfStock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : React.createElement(S.ProductPricing, null, getProductPrice(productPricing, variantPricing, quantity, licensePricing)), noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
    date: new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(purchaseAvailableDate),
    time: new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric"
    }).format(purchaseAvailableDate)
  }), "timeRestrictedAvailability"), isLowStock && renderErrorMessage(intl.formatMessage(commonMessages.lowStock), "lowStockWarning"), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noItemsAvailable), "noItemsAvailable"), simpleAvailability == 'AVAILABLE_SOON' && renderErrorMessage(intl.formatMessage(commonMessages.availableSoon), "availableSoon"), simpleAvailability == 'OUT_OF_STOCK' && renderErrorMessage(intl.formatMessage(commonMessages.availableSoon), "outOfStock"), React.createElement(S.VariantPicker, null, React.createElement(ProductVariantPicker, {
    productVariants: productVariants,
    onChange: onVariantPickerChange,
    selectSidebar: true,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  })), hasComponents ? React.createElement(S.ComponentSelector, null, React.createElement("div", {
    className: "title",
    style: {
      fontWeight: 'bold',
      marginBottom: '10px'
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.AddToCartSection.1189202833",
    defaultMessage: "Additional components"
  })), components.map(function (component) {
    var _component$product;

    var name = ((_component$product = component.product) === null || _component$product === void 0 ? void 0 : _component$product.name) || component.name || component.sku;
    var carr = component.sku.split('_');
    var ctype = carr[0] == 'EXT' && carr.length > 1 ? carr[1] : carr[0];
    return React.createElement("div", {
      key: component.sku
    }, React.createElement(Checkbox, {
      checked: !!selectedComponents[component.id],
      onChange: function onChange() {
        changeComponentSelection(component.id);
      },
      className: 'c_' + ctype
    }, name + ' ' + ctype, React.createElement("br", null), getProductPrice(component.pricing, component.pricing, 1)));
  })) : null, contractUrl && contractUrl.length ? React.createElement(S.Contract, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.AddToCartSection.3542370546",
    defaultMessage: "By purchasing this product you accept the"
  }), " ", React.createElement("a", {
    href: contractUrl,
    target: "_blank"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.AddToCartSection.4085630678",
    defaultMessage: "contract"
  }), " \xBB")) : null, showLicenseWarning ? React.createElement(S.Warning, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.AddToCartSection.2331573190",
    defaultMessage: "If you want to add more devices to an existing license don't use this page, go to My Account > DynFi Licenses instead."
  })) : null, React.createElement(S.Footer, null, React.createElement(S.QuantityInput, null, React.createElement(QuantityInput, {
    quantity: quantity,
    minQuantity: isDynfiLicense ? 4 : 1,
    maxQuantity: isDynfiLicense ? 999 : availableQuantity,
    disabled: isOutOfStock || isNoItemsAvailable,
    onQuantityChange: setQuantity,
    hideErrors: !variantId || isOutOfStock || isNoItemsAvailable,
    testingContext: "addToCartQuantity",
    label: label
  })), React.createElement(AddToCartButton, {
    onSubmit: function onSubmit() {
      return onAddToCart(variantId, quantity, selectedComponents);
    },
    disabled: disableButton
  })));
};

AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;